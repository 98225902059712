.box-option {
    display: flex;
    width: 250px;
    height: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(245, 245, 245);
    border-radius: 8px;
    transition: .15s;
    font-size: 16px;
    cursor: pointer;
    gap: 12px;

    img {
        width: 150px;
        height: 150px;
        object-fit: contain;
    }
}

.box-option:hover,
.box-option.active {
    border: 2px solid rgb(255, 116, 142);
    transform: translateY(12px);
}

.skeleton {
    position: relative;
    width: 250px;
    height: 250px;
    border-radius: 8px;
    overflow: hidden;
    background: rgb(240, 240, 240);
    animation: fade .5s linear alternate infinite;
}

@keyframes fade {
    to {
        background: rgb(250, 250, 250);
    }
}

@media(max-width: 750px) {

    .box-option,
    .skeleton {
        width: 200px;
        height: 200px;

        img {
            width: 125px;
            height: 125px;
        }
    }
}