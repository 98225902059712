.input-group {
    position: relative;
    flex: 1;

    label {
        display: block;
        margin-bottom: 8px;
        font-family: poppins-medium;
        font-size: 14px;
        color: rgb(100, 100, 100);
    }

    input,
    textarea {
        padding: 8px 12px;
        outline: none;
        border: none;
        width: 100%;
        min-width: 300px;
        /* background: rgb(250, 250, 250); */
        border-bottom: 2px solid rgb(235, 235, 235);
        resize: none;
        font-size: 14px;
        color: rgb(70, 70, 70);
    }

    textarea {
        height: calc(100% - 27px);
    }

    .basic-single {
        width: 100%;
        min-width: 300px;
        font-size: 14px;
    }

    input::placeholder,
    textarea::placeholder {
        font-size: 13px;
        color: rgb(180, 180, 180);
    }

    input[type='number']::-webkit-inner-spin-button {
        appearance: none;
    }
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    position: absolute;
    top: 75% !important;
    height: 100%;
    opacity: 0;
    border: none !important;
}

.css-igs3ac {
    border: none !important;
}

.MuiInputBase-inputAdornedEnd {
    transform: translateY(31px) !important;
}

.label-on-field {
    position: absolute;
    display: flex;
    top: 30px;
    height: 35px;
    width: 48px;
    justify-content: center;
    align-items: center;
    background: rgb(255, 255, 255);
    font-size: 14px;
    color: rgb(100, 100, 100);
}

.label-on-field~input {
    padding-left: 48px;
}

@media(max-width: 500px) {
    input {
        min-width: 275px !important;
    }

    .basic-single {
        min-width: 275px !important;
    }

    .basic-single input {
        min-width: 250px !important;
    }
}