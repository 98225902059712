/* This file contains general styles that used by screens and components */

@font-face {
    font-family: noto;
    src: url(../../assets/fonts/notoserif/NotoSerif-VariableFont_wdth\,wght.ttf);
}

@font-face {
    font-family: poppins;
    src: url(../../assets/fonts/poppins/Poppins-Regular.ttf);
}

@font-face {
    font-family: poppins-medium;
    src: url(../../assets/fonts/poppins/Poppins-Medium.ttf);
}

@font-face {
    font-family: poppins-bold;
    src: url(../../assets/fonts/poppins/Poppins-Bold.ttf);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    font-family: poppins;
    user-select: none;
}

button {
    cursor: pointer;
}

/* Color and Theme Variables */

:root {
    --theme: rgb(255, 116, 142);
    --transparent-theme: rgba(255, 116, 142, .6);
    --semantic-theme-1: rgba(219, 38, 157, .1);
    --semantic-theme-2: rgba(201, 75, 3, .01);
    --theme-combination: rgb(252, 199, 8);
    --transparent-theme-combination: rgba(252, 199, 8, .6);
}

.page {
    position: relative;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.semantic-bg {
    position: absolute;
    width: 60vw;
    height: 60vw;
    background: radial-gradient(circle, var(--semantic-theme-1), var(--semantic-theme-2), transparent);
}

.semantic-bg.top {
    top: -35%;
    left: -25%;
}

.semantic-bg.bottom {
    bottom: -35%;
    right: -25%;
}

.layer {
    position: relative;
    display: flex;
    justify-content: center;
    width: 85vw;
    height: 85vh;
    border-radius: 8px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, .07);
    background: rgb(255, 255, 255);
}

/* Start of First Type Screen (Index, Creds and Services Options) */

.option-session {
    display: flex;
    width: 100%;
    height: 95%;
    padding: 48px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.logo img {
    width: 250px;
    height: 100px;
    object-fit: contain;
}

.content {
    margin-top: 48px;
}

.question {
    line-height: 28px;
    text-align: center;
    font-size: 20px;
    color: rgb(100, 100, 100);
}

.option-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    gap: 24px;
}

.box-option-btn {
    background: none;
    outline: none;
    border: none;
}

.content.creds {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.creds-input {
    padding: 12px;
    width: 150%;
    outline: none;
    border: none;
    background: rgb(250, 250, 250);
    border-bottom: 2px solid rgb(235, 235, 235);
    text-align: center;
    font-size: 16px;
}

.creds-input::placeholder {
    font-size: 14px;
    color: rgb(180, 180, 180);
}

.form-button {
    padding: 12px 40px;
    width: max-content;
    background: rgb(255, 255, 255);
    outline: none;
    border: 1px solid rgb(245, 245, 245);
    border-radius: 5px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, .05);
    cursor: pointer;
    font-size: 15px;
}

.form-button:hover {
    filter: brightness(.98);
}

.form-button.on {
    border: none;
    background: var(--theme);
    color: rgb(255, 255, 255);
}

.form-button.on:hover {
    filter: brightness(.9);
}

.btn-group {
    display: flex;
    width: max-content;
    margin-top: 32px;
    margin-left: auto;
    gap: 8px;
}

.back-btn {
    position: absolute;
    top: 24px;
    left: 28px;
    background: none;
    outline: none;
    border: none;
    font-size: 16px;
    color: rgb(100, 100, 100);
}

/* End of First Type Screen (Index, Creds and Services Options) */

/* Start of Second Type Screen (Form) */

.form-session {
    width: 100%;
    max-width: 1400px;
    height: calc(100% - 32px);
    padding: 32px 40px;
    overflow-y: scroll;

    header {
        position: relative;

        h2 {
            font-weight: 500;
            font-size: 24px;
            color: rgb(70, 70, 70);
        }

        p {
            margin: 3px 0 72px;
            color: rgb(100, 100, 100);
        }

        img {
            position: absolute;
            top: -16px;
            right: 0;
            width: 200px;
            height: 100px;
            object-fit: contain;
        }
    }

    .progress {
        position: relative;
        display: flex;
        width: 100%;
        margin: 32px 0 54px;
        justify-content: space-between;
        align-items: center;

        .point {
            width: 20px;
            height: 20px;
            background: rgb(235, 235, 235);
        }

        .point.reached {
            background: var(--theme);
        }

        .line {
            position: absolute;
            left: 0;
            width: 100%;
            height: 3px;
            background: rgb(235, 235, 235);
        }

        .line::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            height: 100%;
            background: var(--theme);
        }

        .line.first::after {
            width: 25%;
        }

        .line.second::after {
            width: 50%;
        }

        .line.third::after {
            width: 75%;
        }

        .line.fourth::after {
            width: 100%;
        }
    }
}

.form-session::-webkit-scrollbar {
    width: 0;
}

.form-body {
    display: flex;
    flex-wrap: wrap;
    gap: 24px 48px;
}

.form-footer {
    margin-top: 48px;

    p {
        font-size: 13px;
    }
}

.form-body .form-col {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: 24px 48px;
}

.form-body .form-col:first-child {
    flex: 2;
}

.double-input {
    position: relative;
    display: flex;
    flex: 1;
    margin-bottom: 24px;
    min-width: 300px;
    gap: 8px;

    input {
        min-width: 0 !important;
    }

    label {
        position: absolute;
        width: max-content;
    }

    .input-group:nth-child(1) {
        position: static;
    }

    .input-group:nth-child(1) input {
        transform: translateY(29px);
    }

    .input-group:nth-child(2) input {
        transform: translateY(26.5px);
    }
}

.already-reserve .double-input {
    .input-group:nth-child(2) input {
        transform: translateY(31px);
    }
}

/* End of Second Type Screen (Form) */

/* Start of Third Type Screen (Reservation) */

.section {
    position: relative;
    margin-bottom: 64px;

    h3 {
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 20px;
        color: rgb(70, 70, 70);
    }
}

.payment-info label {
    font-size: 14px;
}

.payment-info p {
    font-family: poppins-bold;
    font-size: 16px;
}

.modal {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(5px);

    .pop-up {
        max-width: 500px;
    }

    .card {
        padding: 20px 24px;
        background: rgb(255, 255, 255);
        box-shadow: 3px 3px 15px rgba(0, 0, 0, .07);

        .agreement {
            display: flex;
            flex-wrap: nowrap;
            font-size: 14px;
            gap: 12px;

            .check-box {
                display: flex;
                width: 28px;
                height: 28px;
                margin-top: 3px;
                justify-content: center;
                align-items: center;
                outline: none;
                border: none;
                background: rgb(235, 235, 235);
                border-radius: 3px;
                cursor: pointer;
            }

            .check-box.checked {
                background: rgb(255, 116, 142);
            }

            .check-box box-icon {
                display: none;
                transform: translateY(-1px);
            }

            .check-box.checked box-icon {
                display: block;
            }
        }
    }
}

.spinner {
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}

.modal.active {
    display: flex;
}

.notif {
    display: flex;
    margin-bottom: 8px;
    padding: 20px 24px;
    border-radius: 5px;
    background: linear-gradient(130deg, var(--transparent-theme), var(--transparent-theme-combination));
    text-align: justify;
    font-size: 14px;
    color: rgb(255, 255, 255);
    gap: 24px;

    p {
        line-height: 24px;
    }
}

.error-container {
    position: fixed;
    top: 8px;
    right: 8px;
    z-index: 10000;
}

.remove-error-button {
    background: none;
    outline: none;
    border: none;
    display: block;
}

input[type='file'] {
    position: relative;
    padding-left: 0 !important;
    border: none !important;
    color: rgb(250, 250, 250) !important;
    cursor: pointer;
}

input[type='file']::-webkit-file-upload-button {
    background: rgb(255, 255, 255);
    outline: none;
    border: none;
    padding: 8px 32px;
    box-shadow: 3px 3px 15px rgba(0, 0, 0, .07);
    border-radius: 3px;
    cursor: pointer;
    font-size: 13px;
    color: rgb(255, 255, 255);
}

input[type='file'].uploaded::-webkit-file-upload-button {
    background: rgb(50, 225, 100) !important;
    color: rgb(50, 225, 100) !important;
}

input[type='file']~label {
    position: absolute;
    top: 42px;
    left: 0;
    width: 135px;
    text-align: center;
    font-size: 12px;
    pointer-events: none;
}

input[type='file'].uploaded~label {
    color: rgb(255, 255, 255) !important;
}

.status-group {
    display: flex;
    margin-top: 5px;
    margin-bottom: 64px;
    gap: 24px;
}

.status-box {
    display: flex;
    align-items: center;
    gap: 8px;
}

.status-box label {
    font-size: 12px;
}

.status-box p {
    margin: 0 !important;
    padding: 3px 8px;
    border-radius: 3px;
    font-size: 12px;
}

.status-box p.fail {
    background: rgba(255, 0, 0, .3);
}

.status-box p.wait {
    background: rgba(255, 255, 0, .3);
}

.status-box p.success {
    background: rgba(0, 255, 0, .3);
}

.reservation-box p.fail span {
    background: rgba(255, 0, 0, .6);
}

.reservation-box p.wait span {
    background: rgba(255, 255, 0, .6);
}

.reservation-box p.success span {
    background: rgba(0, 255, 0, .6);
}

.view-image {
    text-decoration: none;

    input {
        pointer-events: none !important;
    }
}

.input-mark {
    color: rgb(255, 0, 0);
}

.reservation-list {
    margin-top: 32px;
    max-height: 35vh;
    overflow-y: scroll;
}

.reservation-list::-webkit-scrollbar {
    width: 0;
}

.reservation-box {
    width: 500px;
    margin-bottom: 8px;
    padding: 12px 24px;
    border-radius: 5px;
    border: 2px solid rgb(245, 245, 245);
}

.reservation-box .header {
    display: flex;
    padding-bottom: 12px;
    justify-content: space-between;
}

.reservation-box h1 {
    display: flex;
    margin-bottom: 3px;
    align-items: center;
    letter-spacing: 1px;
    font-family: monospace;
    font-size: 17px;
    color: rgb(100, 100, 100);
}

.reservation-box p {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    color: rgb(150, 150, 150);
}

.reservation-box p span {
    width: 12px;
    height: 12px;
    border-radius: 50%;
}

.reservation-box button {
    border: none;
    background: none;
    color: var(--theme);
}

.reservation-box .footer {
    display: flex;
    padding-top: 12px;
    border-top: 2px solid rgb(245, 245, 245);
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    color: rgb(150, 150, 150);
}

.reservation-box .footer .location {
    display: flex;
    gap: 5px;
}

button.reservation-box {
    margin-top: 12px;
    background: var(--theme);

    h1 {
        letter-spacing: 0;
        font-family: poppins;
        font-size: 14px;
        gap: 8px;
        color: rgb(255, 255, 255);
    }
}

.reservation-skeleton {
    width: 500px;
    height: 100px;
}

.skeleton {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    background: rgb(240, 240, 240);
    animation: fade .5s linear alternate infinite;
}

@keyframes fade {
    to {
        background: rgb(250, 250, 250);
    }
}


/* End of Third Type Screen (Reservation) */

@media(max-width: 900px) {
    .form-session {
        header {
            h2 {
                font-size: 24px;
            }

            p {
                max-width: 45%;
                font-size: 14px;
            }

            img {
                width: 150px;
                height: 100px;
            }
        }
    }

    .status-box {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
    }

    .status-box p {
        max-width: 100% !important;
        font-size: 12px !important;
    }
}

@media(max-width: 750px) {
    .logo img {
        width: 200px;
        height: 75px;
    }

    .content {
        margin-top: 16px;
    }

    .question {
        font-size: 16px;
    }

    .option-group {
        gap: 12px;
    }

    button {
        font-size: 14px !important;
    }

    .reservation-box,
    .reservation-skeleton {
        width: 70vw;
    }
}

@media(max-width: 550px) {
    .form-session {
        header.reservation {
            margin-top: 100px;

            img {
                top: -110px;
                left: -24px;
            }

            p {
                max-width: 100%;
            }
        }
    }

    .user-menu,
    .reservation-list,
    .reservation-box,
    .reservation-skeleton {
        width: 100%;
    }

    .pop-up {
        max-width: 85% !important;
    }
}

@media(max-width: 500px) {
    .layer {
        width: 100vw;
        height: 100vh;
        border-radius: none;
    }

    .layer.menu,
    .option-session {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .user-menu {
        transform: scale(.85);
    }

    .creds-input {
        width: 110%;
        font-size: 14px;
    }

    .creds-input::placeholder {
        font-size: 12px;
    }

    .notif {
        flex-direction: column;
        gap: 0;
    }

    .pop-up .notif,
    .pop-up .card .agreement {
        line-height: 24px;
        font-size: 13px !important;
    }

    .double-input {
        min-width: 275px !important;
    }
}